<template>
  <a-modal 
    v-model="visible" 
    @ok="handleOk" 
    @cancel="cancle" 
    wrapClassName="cancelName" 
    :width='648'
    :closable="false"
    centered
    :maskClosable='false'
    okText="确认驳回"
    :ok-button-props="{ props: { disabled: submitBtn } }"
    >
    <div class="modal_close" @click="cancle">
        <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>申请驳回
      </span>
    </div>
    <div class="cancelContent">
      <p class="messageInfo">是否确认驳回该订单？</p>
    </div>
    <div class="cancelInfo">
      <span class="cancleZi">驳回原因:</span>
      <a-textarea placeholder="请输入驳回原因" allow-clear @change="textChange" :value="textarea"/>
    </div>
  </a-modal>
</template>
<script>
import service from "@/utils/request";
export default {
  // 示例
  // <cancelreason-modal 
  // :visible.sync="visible"
  // info='提示文字'
  // ></message-modal>
  
  name:'cancelreason-modal',
  data(){
    return{
      submitBtn:true,
      selsetClear:false,
      reason:'',
      textarea:'',
    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
  },
  methods:{
    textChange(e){
      if(e.target.value){
        this.submitBtn = false
      }
      this.reason = e.target.value
    },
    handleOk(){
      this.$emit('update:visible',false)
      this.$emit('cancelReasonHandOk',this.reason)
      this.textarea = ''
    },
    cancle(){
      this.$emit('update:visible',false)
      this.textarea = ''
    }
    
  }
}
</script>
<style lang="less" scoped>
  /deep/ .cancelName{
    font-family: PingFangSC-Medium, PingFang SC;
    
    .ant-modal-content{
      text-align:center;
      border-radius: 10px;
      .ant-modal-body{
        padding:30px 0px 0 40px;
        .cancelContent{
          text-align: left;
          padding:0 40px 0 0;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          .ant-tree-node-selected {
            background-color: red;
          }
          .ant-form {
            line-height: 32px;
          }
          .ant-form-item-control-wrapper{
            margin-left:16px;
          }
          .ant-form-item-control{
            line-height: 32px;
          }
          .ant-form-item-label{
            line-height: 32px;
          }
          .ant-form-item{
            margin-bottom:0;
          }
          .messageInfo{
            font-size: 16px;
            font-weight: 400;
            color: #262626;
            line-height: 12px;
            margin-top:8px;
            margin-left:10px;
          }
        }
        .cancelInfo{
          padding:24px 0 50px 0px;
          text-align: left;
          overflow: hidden;
          .cancleZi{
            width: 78px;
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            line-height: 20px;
            float:left;
            padding-left:11px;
            margin-right:9px;
          }
          .ant-input{
            width: 400px;
            height:88px;
            overflow: auto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
          .ant-input-affix-wrapper{
            width: 400px;
          }
        }
        .modal_close {
          position: absolute;
          top: 0;
          right: 0;
          width: 42px;
          height: 42px;
          line-height: 42px;
          border-radius: 0 10px 0 0;
          text-align: center;
          cursor: pointer;
          .modal_close_icon {
            color: #aaa;
            font-size: 16px;
          }
        }
        .modal_close:hover {
          background: #E4F8F7;
          .modal_close_icon {
            color: #08ABA8;
          }
        }
        .modal_title_box {
          text-align: center;
          padding-bottom:50px;
          .modal_title {
            position: relative;
            color: #262626;
            text-align: center;
            z-index: 10;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing:1px;
            .modal_title_line {
              display: inline-block;
              width: 100%;
              position: absolute;
              top: 13px;
              left: 0;
              border-top: 10px solid #E4F8F7;
              z-index: -10;
            }
          }
        }
      }
      p{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        line-height: 30px;
      }
      .ant-modal-footer{
        text-align: center;
        border-top:0px solid #fff;
        padding:0px 0 30px;
        .ant-btn{
          width: 120px;
          height:40px;
          border-radius: 4px;
          font-size: 16px;
          color:#777;
        }
        .ant-btn-primary{
          color: #fff;
          background:#00AAA6;
          margin-left:24px;
        }
      }
    }
  }
</style>


