import { mapState } from "vuex";
// 导入api接口
import {
  getOrderList,
  cancleOrderAll,
  cancleOrderLine,
  addCart,
  exportOrderSap,
  exportOrder,
  Pass,
  Reject
} from "./api.js";
import navList from "../navList";
import ProductItem from "@/components/global/OrderDetail/ProductItem";
import CheckModel from "./components/CheckModel";
import PassModel from "./components/PassModel";
import Util from "@/utils/utils";

export default {
  data() {
    return {
      navList: [], //导航数据
      spinOrderExport: false,
      spinShenExport: false,
      pageLoadFlag: false, //load标志
      creatDate: 1,
      isView: false,
      dateFormat: "YYYY-MM-DD",
      orderList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/channelOrder/distributorPurchase",
          name: "distributorPurchase",
          title: "分销直采订单"
        }
      ],
      postDate: {
        type: 2,
        pageNo: 1,
        searchStr: "",
        orderTypeCode: "",
        sapOrderStatus: "",
        matklId: null,
        orgId: null,
        status: "",
        orderBy: "desc",
        beginDate: "",
        endDate: "",
        // timeFrame: "1",
        timeAuditFrame: "",
        sapBeginDate: "",
        sapEndDate: "",
        weekName: "",
        formCode:'salesOrderListFx',
        // orderTimeGroup: "7,," //默认最近一个月
      },
      totalPages: "",
      total: "0",
      currentPage: "",
      searchOrderNo: "", //订单号
      visible: false, //是否显示取消订单弹框
      ModalText: "确定取消订单？",
      confirmLoading: false,
      cancleData: { searchStr: "" },
      nolistImg: require("@/assets/order/noList.png"),
      showList: false,
      orderTypeId: "",
      ordertypeCancel: 1,
      shenLoading: false,
      orderLoading: false,
      isFenxiaoSign: "",
      isFenxiaoSignActive: "",
      isChange: false,
      checkVisible: false,
      passVisible: false
    };
  },
  components: {
    ProductItem,
    CheckModel,
    PassModel
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: state => state.filtrate.filList,
      isFenxiaoOrder: state => state.user.userInfo.account.marketModels,
      account: state => state.user.userInfo.account.account,
      //筛选组件加载loadding
      filterLoadFlag: state => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: state => state.filtrate.filterConfigStr
    })
  },
  mounted() {
    this.navList = navList;
    if (
      (this.isFenxiaoOrder.indexOf("17452") !== -1 ||
        this.isFenxiaoOrder.indexOf("17453") !== -1) &&
      this.isFenxiaoOrder.indexOf("17451") == -1
    ) {
      this.isFenxiaoSign = "2";
    } else if (
      this.isFenxiaoOrder.indexOf("17451") !== -1 &&
      this.isFenxiaoOrder.indexOf("17452") == -1 &&
      this.isFenxiaoOrder.indexOf("17453") == -1
    ) {
      this.isFenxiaoSign = "1";
      this.$router.push({
        path: "/order/dms"
      });
    } else if (
      (this.isFenxiaoOrder.indexOf("17451") !== -1 &&
        this.isFenxiaoOrder.indexOf("17452") !== -1) ||
      this.isFenxiaoOrder.indexOf("17453") !== -1
    ) {
      this.isFenxiaoSign = "3";
      this.isFenxiaoSignActive = "2";
    }
    // //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    this.filterFrom = {
      ...this.filterFrom,
      formCode: this.filterConfigStr ? this.filterConfigStr : ""
    };
    //海信采购
    this.$store.commit("filtrate/SET_CONFIG_STR", {
      toPath: this.$route,
      marketModels: "17452"
    });
  },
  methods: {
    onFineshedchange(e) {
      if (e.target.checked == true) {
        this.postDate.status = "AGENTUNCHKED";
      } else {
        this.postDate.status = "";
      }
      this.getOrderLi();
    },
    //驳回弹框
    checkReject(item) {
      this.checkVisible = true;
      this.selRow = item;
    },
    //通过弹框
    checkPass(item) {
      this.passVisible = true;
      this.selRow = item;
    },
    //通过
    passSubmit() {
      Pass({
        ids: this.selRow.id
      })
        .then(res => {
          if (res.data && res.data.code == 0) {
            this.$message.success("通过成功！");
            this.getOrderLi();
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch(error => {
          this.$message.warning("通过失败");
        });
    },
    //驳回
    rejectSubmit(remark) {
      Reject({
        ids: this.selRow.id,
        remark: remark
      })
        .then(res => {
          if (res.data && res.data.code == 0) {
            this.$message.success("驳回成功！");
            this.getOrderLi();
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch(error => {
          this.$message.warning("驳回失败");
        });
    },
    postSpinTrue(value) {
      this.pageLoadFlag = value;
    },
    handleTab(type) {
      if (this.isFenxiaoSign == "3") {
        this.isFenxiaoSignActive = type;
      }
      this.isDataViewMoreFun();
      if (type == 1) {
        //渠道采购
        // this.getDmsOrderli();
        this.isGetDmsFlag = true;
        this.$router.push({
          path: "/order/dms"
        });
      } else {
        //海信采购
        // this.$store.commit("filtrate/SET_CONFIG_STR", {toPath: this.$route, marketModels: '17452'});
        // this.getOrderLi()
        // this.isGetDmsFlag = false;
      }
    },
    //判断数据是否有选中 赋值class
    isDataViewMoreFun() {
      // isView?'type-wrap-active':''
      let cls = "";
      let count = 0;
      for (let itemL1 of this.filList) {
        for (let itemL2 of itemL1.list) {
          if (itemL2.isActive && itemL2.name != "全部") {
            count++;
          }
        }
      }
      // debugger
      if (count > 0) {
        cls = "fil-checked";
      }
      return cls;
    },
    // 再来一单
    againOrder(id, code) {
      let carts = id;
      let versions = "";
      let orgAndGroup = "";
      let purchaseType = "";
      this.orderList.forEach(item => {
        if (item.id == id) {
          item.items.forEach(goodsItem => {
            orgAndGroup = item.orgId + "-" + goodsItem.matklId;
          });
        }
      });
      // versions = versions.substring(0,versions.length-1)
      if (code == "应急订单") {
        purchaseType = "1";
      } else if (code == "常规订单") {
        purchaseType = "2";
      }
      this.$router.push({
        path: "/confirm/orderAgain",
        query: {
          carts: carts,
          versions: versions,
          orgAndGroup: orgAndGroup,
          purchaseType: purchaseType
        }
      });
    },
    toDetail(proCode, orgId) {
      this.$router.push({
        path: "/product/detail",
        query: { productCode: proCode, orgId: orgId }
      });
    },
    // 订单导出
    downloadFile(params) {
      var blob = params;
      if (
        "download" in document.createElement("a") &&
        navigator.userAgent.indexOf("Edge") == -1
      ) {
        var elink = document.createElement("a");
        elink.download = "采购订单明细.xls";
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, "采购订单明细.xls");
      }
    },
    // 审核单导出
    downloadFileShen(params) {
      var blob = params;
      if (
        "download" in document.createElement("a") &&
        navigator.userAgent.indexOf("Edge") == -1
      ) {
        var elink = document.createElement("a");
        elink.download = "采购订单明细.xls";
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, "采购订单明细.xls");
      }
    },
    shaiClick() {
      this.isView = !this.isView;
    },
    // 搜索
    onSearchOrderCode() {
      for (var a in this.postDate) {
        this.postDate[a] = "";
      }
      this.postDate.type = 2;
      this.postDate.pageNo = 1;
      // this.postDate.timeFrame = 1;
      // this.postDate.orderBy = 'desc'
      this.postDate.searchStr = this.searchOrderNo;
      this.getOrderLi();
    },

    // 订单导出
    exportOrderBtn() {
      let exportObj = this.postDate;
      delete exportObj.orderBy;
      this.orderLoading = true;
      exportOrder(exportObj).then((res) => {
            debugger
            Util.blobToJson(res.data).then(content=>{
              debugger
              if(content&&content.msg=='success'){
                this.$message.success('下载成功，请到下载中心查看!')
              }
            }).catch(err=>{
              Util.downloadFile(res.data,'采购订单明细.xls')
            }).finally(()=>{
              this.orderLoading = false;
            })
          })
          .catch((error) => {
            this.orderLoading = false;
            console.log("订单导出", error);
          });
    },
    // 审核单导出
    exportOrderSap() {
      let exportObj = this.postDate;
      delete exportObj.orderBy;
      this.shenLoading = true;
      exportOrderSap(exportObj)
        .then(res => {
          if (res.status == 200) {
            this.shenLoading = false;
            this.downloadFileShen(res.data);
          }
        })
        .catch(error => {
          console.log("审核单导出", error);
        });
    },
    // 加入购物车
    addCart(proid, orgid) {
      let data = { productId: proid, orgId: orgid, num: 1 };
      addCart(data)
        .then(res => {
          if (res.data.code == 0) {
            this.$message.success("加入购物车成功");
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch(error => {
          this.$message.warning("加入购物车失败");
          console.log("加入购物车", error);
        });
    },
    // 取消行订单
    cancleOrderLine(orderType, statusCode, id) {
      let obj = {
        orderTypeId: orderType,
        orderStatusCode: statusCode,
        id: id,
        type: 2
      };
      this.cancelOrder(obj);
    },
    cancelOrder(item) {
      const orderType = item.orderTypeId;
      const states = item.orderStatusCode;
      this.orderTypeId = item.id;
      this.ordertypeCancel = item.type;
      if (
        orderType == "8311" &&
        (states == "ALREADYPLANPRODUCT" ||
          states == "ARRANGEDPRODUCT" ||
          states == "UNCHKED" ||
          states == "WAITDELIVER" ||
          states == "PARTCHECKED")
      ) {
        this.$confirm({
          title: "取消提醒",
          content:
            "取消‘评审通过’,‘已安排生产’,‘待排发货计划’,‘待发货’,‘发货中’状态的常规订单，会判定为商家违约，请确认是否取消？",
          onOk: () => {
            this.visible = true;
          },
          onCancel() {}
        });
      } else {
        this.visible = true;
      }
    },
    getReason(value) {
      this.cancelReason = value;
      this.cancleSubmit(this.orderTypeId, this.ordertypeCancel);
    },
    // 取消订单方法
    cancleSubmit(id, type) {
      this.pageLoadFlag = true;
      let obj = { id: id, cancelReason: this.cancelReason };
      if (type == 2) {
        cancleOrderLine(obj)
          .then(res => {
            if (res.data.type == "Y") {
              this.pageLoadFlag = false;
              this.$message.success("订单取消成功");
              this.getOrderLi();
            } else {
              this.pageLoadFlag = false;
              this.$message.warning("取消失败", res.data.msg);
            }
          })
          .catch(error => {
            this.pageLoadFlag = false;
            console.log(error);
            this.$message.warning("订单取消失败");
          });
      } else {
        cancleOrderAll(obj)
          .then(res => {
            if (res.data.type == "Y") {
              this.$message.success("订单取消成功");
              this.getOrderLi();
            } else {
              this.pageLoadFlag = false;
              this.$message.warning("取消失败", res.data.msg);
            }
          })
          .catch(error => {
            this.pageLoadFlag = false;
            console.log(error);
            this.$message.warning("订单取消失败");
          });
      }
    },
    // 取消整单
    cancleOrderAlle(orderType, statusCode, id) {
      //取消整单
      let obj = {
        orderTypeId: orderType,
        orderStatusCode: statusCode,
        id: id,
        type: 1
      };
      this.cancelOrder(obj);
    },
    handleOk() {
      this.confirmLoading = true;
      cancleOrderAll(this.cancleData)
        .then(res => {
          if (res.data.type == "Y") {
            this.confirmLoading = false;
            this.getOrderLi();
          }
        })
        .catch(error => {
          console.log("取消失败", error);
        });
    },
    handleCancel() {
      this.visible = false;
    },
    // 时间排序
    changeFang() {
      if (this.creatDate == 0) {
        this.creatDate = 1;
        this.postDate.orderBy = "desc";
      } else if (this.creatDate == 1) {
        this.creatDate = 2;
        this.postDate.orderBy = "asc";
      } else {
        this.creatDate = 0;
        this.postDate.orderBy = "";
      }
      this.getOrderLi();
    },
    onFilterChange(checkedObj) {
      //合并入参请求对象
      this.postDate = { ...this.postDate, ...checkedObj[0], pageNo: 1 };
      //查询列表
      this.getOrderLi();
    },
    goTo(path) {
      let routeUrl = this.$router.resolve({
        path: path,
      });
      window.open(routeUrl.href, "_blank");
    },
    getOrderLi() {
      this.pageLoadFlag = true;
      this.showList = false;
      getOrderList(this.postDate)
        .then(res => {
          this.pageLoadFlag = false;
          this.orderList = res.data.orderHeaderList||[];
          this.orderList.forEach(item => {
            item.items.forEach(goodsItem => {
              if (goodsItem.billPrice.toString().indexOf(".") == -1) {
                goodsItem.billPrice = Number(goodsItem.billPrice).toFixed(2);
              }
              if (goodsItem.basePrice.toString().indexOf(".") == -1) {
                goodsItem.basePrice = Number(goodsItem.basePrice).toFixed(2);
              }
            });
          });
          this.totalPages = res.data.totalPages||0;
          this.currentPage = res.data.currentPage;
          this.total = res.data.totalCount||0;
          this.showList = true;
        })
        .catch(error => {
          this.pageLoadFlag = false;
          this.totalPages = 0;
          this.currentPage = 1;
          this.total = 0;
          this.showList = true;
          console.log("列表", error);
        });
    },
    clickMore() {
      this.pageLoadFlag = true;
      this.postDate.pageNo = this.postDate.pageNo + 1;
      this.currentPage = this.postDate.pageNo;
      getOrderList(this.postDate)
        .then(res => {
          this.orderList = this.orderList.concat(res.data.orderHeaderList||[]);
          this.orderList.forEach(item => {
            item.items.forEach(goodsItem => {
              if (goodsItem.billPrice.toString().indexOf(".") == -1) {
                goodsItem.billPrice = Number(goodsItem.billPrice).toFixed(2);
              }
              if (goodsItem.basePrice.toString().indexOf(".") == -1) {
                goodsItem.basePrice = Number(goodsItem.basePrice).toFixed(2);
              }
            });
          });
          this.pageLoadFlag = false;
          this.totalPages = res.data.totalPages;
          this.total = res.data.totalCount;
          this.currentPage = res.data.currentPage;
        })
        .catch(error => {
          this.pageLoadFlag = false;
          this.showList = true;
          console.log("下拉", error);
        });
    }
  }
};
